import React from 'react'
import _ from "lodash"
import Helmet from 'react-helmet'
import Visite from '../components/visite'
import Contatti from '../components/contatti'
import Breadcrumbs from '../components/breadcrumbs'
import Curriculum from '../components/curriculum'

import Layout from "../components/layout"
import { graphql } from "gatsby"

const noprenota = ["Redattore Web e Amministrativo","Amministrativo","Tecnico","Redattore Web e Referente IT"];

class PersonaTemplate extends React.Component {

  render() { 
    const data = this.props.data.contentfulPersona;
    const breadcrumb = [
        {"title":"Staff","link":"/staff/"},
        {"title":`${data.cognome} ${data.nome}`}];
    /* let servizi = this.props.data.allContentfulSchedaServizio;
    servizi = !!servizi?servizi.edges:[]; */
    let diagnostici = this.props.data.allContentfulSchedaServizioDiagnostico;
    diagnostici = !!diagnostici?diagnostici.edges:[];
   
    let btn = null;
    if (_.find(diagnostici, ['node.friendlyUrl', "anatomia-patologica"])) {
      btn = {"label":"Come conferire un campione: Modulisitca","link":"/anatomia-patologica#conferire"};
    } else if (_.find(diagnostici, ['node.friendlyUrl', "patologia-e-diagnostica-di-laboratorio"])) {
      btn = {"label":"Come conferire un campione: Modulisitca","link":"/patologia-e-diagnostica-di-laboratorio#conferire"};
    }
    
    let thumb = data.immagine?data.immagine.file.url:"/images/staff-placeholder.png";
    return (
      <Layout  location={this.props.location}>
        <div className="container">
          <Helmet title={data.nome +" "+ data.cognome}/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <article className="staff">
                <h1 className="staff__name">{data.nome} {data.cognome}</h1>
                <h2 className="staff__role">{data.ruolo}</h2>
                <div className="row">
                  <div className="col-md-4 text-center">
                      <img src={thumb} alt={data.nome + data.cognome} className="staff__img" />
                  </div>
                  <div className="col-md-8">
                    {!!data.bio &&
                      <div className="staff__text" dangerouslySetInnerHTML={{ __html: data.bio.childMarkdownRemark.html }}>

                      </div>
                    }
                  </div>
                </div>
             
              </article>  
            </div>
            <div className="col-md-4">
              {!!data.curriculum &&
                <Curriculum data={data.curriculum}/>
              }
              <Contatti data={data}/>
              {!!btn && !noprenota.includes(data.ruolo) &&
                <Visite btn={btn}/>
              }
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default PersonaTemplate;

export const pageQuery = graphql`query PersonaDetailQuery($contentful_id: String!) {
  contentfulPersona(contentful_id: {eq: $contentful_id}) {
    id
    nome
    cognome
    ruolo
    telefono
    email
    curriculum {
    	title
      file {
        url
      }
    }  
    bio {
      childMarkdownRemark {
        html
      }
    }
    immagine {
      file {
        url
      }
    }
  },
  allContentfulSchedaServizio(filter: {team: {elemMatch: {contentful_id: {eq: $contentful_id}}}, node_locale: {eq: "it-IT"}} ) {
    edges {
     node {
       id
       titolo
       friendlyUrl
       specie
     } 
   }
 }
 allContentfulSchedaServizioDiagnostico(filter: {team: {elemMatch: {contentful_id: {eq: $contentful_id}}}, node_locale: {eq: "it-IT"}} ) {
  edges {
   node {
     id
     titolo
     friendlyUrl
   } 
 }
}
}
`;
