import React from 'react'


class Visite extends React.Component {

  render() { 
    const default_btn = {
      "label":"Prenota visita",
      "link":"/prenota-visita/"
    }
    const prenotabile = this.props.prenotabile == null?true:this.props.prenotabile;
    const btn = {...default_btn, ...this.props.btn};
 
    return (
      <div className="webcontent">
          <h2 className="webcontent__title">Visite</h2>
          
          {prenotabile &&
            <a href={btn.link} title={btn.link} className="btn-link btn-link--has-icon" style={{backgroundImage: 'url(/images/contents/icona_stethoscope.png)'}}>{btn.label}</a>
          }  
      </div>
    )
  }

}

export default Visite;