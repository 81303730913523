import React from 'react'

class Curriculum extends React.Component {

  render() { 
    const cv = this.props.data;
   
    return (
      <div className="webcontent">
        <h2 className="webcontent__title">Curriculum</h2>
        <ul className="webcontent__list">
          
          <li className="webcontent__item">
            <a href={cv.file.url} title={cv.title} className="webcontent__link">{cv.title}</a>
          </li>
        
        </ul>
      </div>
    )
  }

}

export default Curriculum;